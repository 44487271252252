import React from "react";
import Layout from "../../components/layout";
import AIAndML from "../../components/views/solutions/ai-and-ml";

const AiAndMlPage = () => {
  return (
    <Layout>
      <AIAndML />
    </Layout>
  );
};

export default AiAndMlPage;
